<template>
  <div>
    <v-container>
      <h1 class="page-headline x-small">Bestellung verifizieren</h1>
      <div class="page-divider small mb-4"/>
      <v-card class="mb-4">
        <v-card-text style="font-size: 24px;line-height: 30px">
          Danke für deine Bestellung! Du musst nun im Spiel im Chat (
          <v-icon size="20">mdi-alpha-t-box-outline</v-icon>
          ) folgenden Befehl eingeben:
          <br/><br/>
          Bestätigen: <tt>/bestellung {{ orderId }}</tt><br/>
          Stornieren: <tt>/storno {{ orderId }}</tt>
        </v-card-text>
      </v-card>

      <v-alert type="error" outlined shaped>
        <b>Wichtig Information</b><br/> Wenn du die Bestellung mit dem Befehl bestätigst wird deine aktuelle Position dem Restaurant
        gemeldet. Beachte bitte, dass du deine Bestellung auch bezahlen <b>musst</b>. Solltest du deine Bestellung
        nicht bezahlen, kann rechtlich wegen Betrug gegen dich vorgegangen werden.
      </v-alert>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    orderId: null,
  }),
  mounted() {
    this.orderId = this.$route.params.id;
  }
}
</script>