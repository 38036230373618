<template>
  <v-app>
    <v-app-bar color="primary" app dark fixed elevation="3" @click="toMainPage" style="cursor: pointer">
      <v-toolbar-title>
        <v-icon class="pr-1 pb-1">mdi-food-outline</v-icon>
        Food Delivery
      </v-toolbar-title>

      <v-spacer v-if="developmentMode"/>
      <v-btn small color="info" v-if="developmentMode" elevation="12" tile>
        Entwicklermodus
      </v-btn>
    </v-app-bar>

    <v-main>
      <div class="d-inline">
        <!-- <router-view/> -->
        <h3>Dieser Service wurde vorläufig eingestellt.</h3>
      </div>
    </v-main>

    <v-footer color="primary darken-2" app dark>
      Made with
      <v-icon left right color="red">mdi-heart</v-icon>
      by Altera Vita (Build {{ $VERSION }})
      <v-spacer/>
      <router-link to="/impressum" style="color: white;">Impressum</router-link>
    </v-footer>
  </v-app>
</template>

<!--suppress CssUnknownTarget, CssUnusedSymbol -->
<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');


.v-application {
  font-family: "Montserrat", "Nunito", sans-serif !important;
}

.scrollbar-overflow-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-overflow-hidden {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbox {
  height: 80vh !important;
  max-height: 80vh !important;
}

.text-box {
  font-family: "Nunito", "Roboto", sans-serif !important;
  line-height: 27px;
}

.swal2-container {
  font-family: "Nunito", "Roboto", sans-serif !important;
}

.v-card__title > p {
  display: inline !important;
  margin: 0 !important;
}

.text-box > p {
  font-size: 19px;
  display: block;
  margin: 0;
  padding: 0;
}

.theme--light .text-box > h1 h2 h3 h4 h5 h6 {
  color: rgb(81, 81, 81) !important;
}

.theme--light .text-box > p {
  color: rgb(71, 71, 71) !important;
}

.theme--dark .text-box > h1 h2 h3 h4 h5 h6 {
  color: rgb(255, 255, 255) !important;
}

.theme--dark .text-box > p {
  color: rgb(205, 205, 205) !important;
}

.alert > p {
  margin: 0 !important;
  padding: 0 !important;
}

.swal2-popup {
  background-color: #353535 !important;
  color: white !important;
}

.page-headline {
  font-family: 'Leckerli One', 'Montserrat', 'Nunito', sans-serif;
  font-size: 72px !important;
  font-weight: normal;
  color: white;
}

.page-headline.center {
  text-align: center;
}

.page-headline.small {
  font-size: 40px !important;
}

.page-headline.x-small {
  font-size: 32px !important;
}

.page-headline.xx-small {
  font-size: 24px !important;
}

.page-text {
  font-family: 'Aleo', 'Montserrat', 'Nunito', sans-serif;
  font-size: 20px !important;
  font-weight: normal;
}

.page-text.small {
  font-size: 17px !important;
}

.page-text.x-small {
  font-size: 13px !important;
}

.page-text.center {
  text-align: center;
}

input {
  font-family: 'Leckerli One', 'Montserrat', 'Nunito', sans-serif !important;
  font-size: 23px !important;
}

.page-divider {
  background-color: #689F38;
  height: 11px;
  display: block;
}

.page-text.colored {
  color: #689F38 !important;
}

.page-text.colored-red {
  color: #FFCDD2 !important;
}

.page-text.bold {
  font-weight: bold !important;
}

.page-divider.small {
  height: 5px;
}

.money {
  font-family: 'Nova Mono', sans-serif;
  font-weight: bolder !important;
}
</style>

<script>
export default {
  name:       'App',
  computed:   {
    developmentMode() {
      return process.env.NODE_ENV === 'development';
    },
  },
  data:       () => ({
    notifications: [],
  }),
  components: {},
  methods:    {
    toMainPage() {
      this.$router.push('/');
    }
  },
  mounted() {
    this.updateVuetifyTheme();

    window.document.title = 'Food Delivery for FiveM Altera Vita RP';
  }
}
</script>
