import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import Logout from "../views/Logout";
import LandingPage from "@/views/LandingPage.vue";
import Impressum from "@/views/Impressum.vue";
import BusinessSearch from "@/views/BusinessSearch.vue";
import Menu from "@/views/Menu.vue";
import PrepareOrder from "@/views/PrepareOrder.vue";
import VerifyPage from "@/views/VerifyPage.vue";

Vue.use(VueRouter)

const Router = new VueRouter({
    //mode: 'history',
    routes: [
        {
            path:      '/',
            component: LandingPage
        },
        {
            name:      'Login',
            path:      '/login',
            component: Login
        },
        {
            name:      'Logout',
            path:      '/logout',
            component: Logout
        },
        {
            name:      'Impressum',
            path:      '/impressum',
            component: Impressum
        },
        {
            name:      'DeliverySearch',
            path:      '/search',
            component: BusinessSearch
        },
        {
            name:      'BusinessMenu',
            path:      '/menu/:id',
            component: Menu
        },
        {
            name:      'PrepareOrder',
            path:      '/order/:id',
            component: PrepareOrder
        },
        {
            name:      'VerifyOrder',
            path:      '/verify/:id',
            component: VerifyPage
        }
    ],
})

export default Router;