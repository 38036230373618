<template>
  <v-container fluid fill-height>
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4 elevation-6>
        <v-toolbar dense class="primary" elevation="2" tile>
          <v-toolbar-title class="white--text"><h4>Hallo!</h4></v-toolbar-title>
        </v-toolbar>
        <v-card outlined elevation="4" tile>
          <v-card-text class="pt-2">
            <div v-if="!success">
              <v-form ref="form">
                <v-text-field label="Deine Dienstnummer (z.B.: DRK-01)" v-model="username" required
                              solo dense outlined
                              prepend-inner-icon="mdi-account"
                              @keydown.enter="signIn" :error="error"/>

                <v-text-field label="Dein Passwort" v-model="password" min="8"
                              prepend-inner-icon="mdi-text"
                              :append-icon="hiddenPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                              :type="hiddenPassword ? 'password' : 'text'" counter required
                              solo dense outlined
                              @click:append="hiddenPassword = !hiddenPassword" @keydown.enter="signIn" :error="error"/>

                <v-text-field v-if="playerIdentifier" label="Spieler Identifier" v-model="playerIdentifier" disabled
                              solo dense outlined/>

                <v-layout justify-space-between>
                  <v-btn :color="loginButtonColor" small @click="signIn" :loading="loading">
                    <v-icon left>mdi-login-variant</v-icon>
                    Anmelden
                  </v-btn>
                </v-layout>
              </v-form>
            </div>
            <div v-else>
              <v-alert type="success" dense outlined border="left">
                Du wurdest angemeldet! Willkommen {{ user.displayName }}
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style>

</style>

<script>
export default {
  data: () => ({
    username: '',
    password: '',
    playerIdentifier: null,

    loading: false,
    success: false,
    error: null,

    hiddenPassword: true,
  }),
  computed: {
    loginButtonColor() {
      if (this.error) {
        return 'error';
      }
      return 'primary';
    }
  },
  methods: {
    signIn() {
      if (this.loading || this.success) return;

      this.loading = true;
      this.error = null;

      this.apiSignIn(this.username, this.password, this.playerIdentifier).then(response => {
        this.$store.commit('update', {user: response.data})
        this.success = true;
        this.updateVuetifyTheme();

        setTimeout(() => {
          if (this.$route.path === '/')
            this.$router.push('/dashboard-' + response.data.accountType.toLowerCase())
        }, 2000);
      }).catch(error => {
        this.error = error;
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.playerIdentifier = null;

    if (this.isAuthenticated) {
      this.$router.push('/dashboard-' + this.user.accountType.toLowerCase());
    } else {
      this.playerIdentifier = this.$route.query.playerIdentifier;
    }
  }
}
</script>