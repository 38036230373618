import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify';
import router from './plugins/router';
import store from './plugins/vuex';
import api from './plugins/api';

import 'quill/dist/quill.core.css';
import 'vue2-editor/dist/vue2-editor.css';
import 'leaflet/dist/leaflet.css';

Vue.mixin(api);

Vue.prototype.$VERSION = process.env.VUE_APP_VERSION || 'dev-snapshot';

Vue.prototype.$SERVER_URL   = process.env.VUE_APP_SERVER_URL || 'http://localhost:5000';
Vue.prototype.$FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL || 'gov.alteravitarp.de';

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    store,

    render: h => h(App),
}).$mount('#app')
