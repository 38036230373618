<template>
  <div>
    <div class="d-flex align-center product-item" v-for="(v, k) in cartItems" :key="k">
      <div class="mr-4">
        <v-avatar rounded class="mb-1" size="28">
          <img :src="'https://fivem.r3ktm8.de/items/' + v.product.name + '.png'"/>
        </v-avatar>
      </div>
      <div class="me-auto">
        <h5 class="page-headline xx-small">{{ v.product.label }}</h5>
        <p v-if="v.additives" class="page-text x-small">{{ v.additives }}</p>
      </div>
      <div class="pr-3">{{ v.count }}x</div>
      <v-btn icon class="money" text color="primary" @click="cartRemoveProduct(v.product)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div v-if="cart.items.length === 0" class="pt-3">
      <v-alert type="warning" outlined dense>
        Dein Warenkorb ist Hungrig!
      </v-alert>
    </div>

    <h3 class="page-headline xx-small mt-2">Zwischensumme: {{ cartTotalSum | money }}</h3>
    <div class="page-divider small mb-3"/>

    <div v-if="cart.items.length > 0 && $route.name === 'BusinessMenu'">
      <v-btn small color="info darken-3" @click="prepareOrder()">
        Bestellung abschließen
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    cart() {
      return this.$store.state.cart;
    },

    cartItems() {
      let arr = [...this.cart.items];

      if (this.cart.delivery && this.shop.deliveryCost) {
        arr.push({
          id:      'UNKNOWN_PRODUCT_ID',
          count:   1,
          product: {name: 'delivery', label: 'Lieferkosten', price: this.shop.deliveryCost}
        })
      }

      return arr;
    },
  },

  methods: {
    prepareOrder() {
      this.$router.push('/order/' + this.shop.id)
    },
  },

  props: {
    shop: {
      type: Object
    },
  }
}
</script>