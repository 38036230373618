<template>
  <div>
    <v-parallax :src="require('../assets/background.jpg')" height="600">
      <h1 class="page-headline center">Food Delivery</h1>
      <br/><br/>
      <div class="d-flex justify-center">
        <div class="d-flex">
          <v-text-field light v-model="zipCode" solo prepend-inner-icon="mdi-earth"
                        placeholder="Deine Postleitzahl" style="max-width: 500px;" height="50" @keyup.enter="search"/>
          <v-btn dark small color="primary" tile style="height: 50px;margin-left: -2px;" @click="search">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </div>
    </v-parallax>
    <v-div class="page-divider"></v-div>
    <v-container>
      <h2 class="page-headline small center">Bestell jetzt dein Lieblingsessen an deine Position!</h2>
      <p class="page-text center">Einfach Postleitzahl eingeben, Restaurant aussuchen und auf Pizza, Döner, Burger, Süßwaren,
        Getränke und vieles mehr freuen.</p>
    </v-container>
    <v-container style="width: 1100px" class="hidden-sm-and-down">
      <h3 class="page-headline x-small center">So einfach gehts!</h3>
      <div class="page-divider small mb-4 mt-2"/>
      <div class="d-flex justify-center">
        <div style="width: 250px !important;">
          <v-icon color="primary" size="100" class="d-flex justify-center">mdi-map-marker-circle</v-icon>
          <br/>
          <p class="page-text center" style="text-align: center">
            Aktuelle Postleitzahl eingeben
          </p>
        </div>
        <div style="width: 250px !important;">
          <v-icon color="primary" size="100" class="d-flex justify-center">mdi-food-outline</v-icon>
          <br/>
          <p class="page-text center" style="text-align: center">
            Essen und Getränke auswählen
          </p>
        </div>
        <div style="width: 250px !important;">
          <v-icon color="primary" size="100" class="d-flex justify-center">mdi-bell-badge-outline</v-icon>
          <br/>
          <p class="page-text center" style="text-align: center">
            Status-Updates nachverfolgen
          </p>
        </div>
        <div style="width: 250px !important;">
          <v-icon color="primary" size="100" class="d-flex justify-center">mdi-moped-outline</v-icon>
          <br/>
          <p class="page-text center" style="text-align: center">
            Lieferung erhalten und vor Ort Bar oder mit Karte bezahlen
          </p>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data:    () => ({
    zipCode: '',
  }),
  methods: {
    search() {
      let zip = this.zipCode * 1;

      if (zip >= 1000 && zip <= 11000) {
        this.$store.commit('update', {zipCode: this.zipCode})
        this.$router.push('/search')
      } else {
        Swal.fire({icon: 'error', title: 'Fehler bei der Eingabe', html: 'Deine Postleitzahl muss zwischen 1000 und 10999 sein.'})
      }
    }
  }
}
</script>