<template>
  <div>
    <v-container v-if="shop && products.length > 0">
      <h3 class="page-headline small">Speisekarte • {{ shop.label }}</h3>
      <div class="page-divider mb-4"/>

      <v-alert v-if="!shop.open" type="warning" elevation="8">
        <b>Momentan geschlossen</b><br/>
        Der gewählte Anbieter hat aktuell geschlossen. Du kannst dir dennoch die Speisekarte ansehen.
      </v-alert>

      <v-row>
        <v-col>
          <h5 class="page-headline x-small">Kategorien</h5>
          <div class="page-divider small mb-4"/>
          <v-row>
            <v-col cols="2" v-for="(v, k) in categories" :key="k">
              <v-card @click="scrollToCategory(v)">
                <v-card-text class="page-text center bold" style="cursor: pointer">{{ v }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-spacer class="pb-7"/>

          <div v-for="(v, k) in categories" :key="k" :id="v" class="pb-4">
            <div class="d-flex">
              <h5 class="page-headline x-small me-auto">{{ v }}</h5>
              <h5 class="page-headline xx-small ma-2 mr-4 ml-4">Preis</h5>
            </div>
            <div class="page-divider small mb-4"/>

            <div class="d-flex align-center product-item" v-for="(v, k) in productsOf(v)" :key="k">
              <div class="mr-4">
                <v-avatar rounded class="mb-3">
                  <img :src="'https://fivem.r3ktm8.de/items/' + v.name + '.png'"/>
                </v-avatar>
              </div>
              <div class="me-auto">
                <h5 class="page-headline xx-small">{{ v.label }}</h5>
                <p v-if="v.additives" class="page-text x-small">{{ v.additives }}</p>
              </div>
              <v-btn class="money" text color="primary" @click="cartAddProduct(v)">{{ v.price | money }}</v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="12" sm="12">
          <h5 class="page-headline x-small">Mein Warenkorb</h5>
          <div class="page-divider small mb-4"/>

          <cart-overview :shop="shop"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style>
.product-item {
  padding: 10px;
}

.product-item:nth-child(2n) {
  background-color: #232323;
}
</style>

<script>
import CartOverview from "@/components/CartOverview.vue";

export default {
  components: {CartOverview},
  data:       () => ({
    shop:     null,
    products: [],

    showCart: false,
  }),
  computed:   {
    cart() {
      return this.$store.state.cart;
    },

    categories() {
      let categories = [];

      this.products.forEach(obj => {
        if (!categories.includes(obj.category)) categories.push(obj.category);
      })

      return categories;
    },
  },
  methods:    {
    prepareOrder() {
      this.$store.commit('update', {cart: this.cart});
      this.$router.push('/order/' + this.shop.id)
    },
    scrollToCategory(category) {
      document.getElementById(category).scrollIntoView()
    },
    productsOf(category) {
      let filteredProducts = [];

      filteredProducts = this.products.filter(product => product.category === category);

      return filteredProducts;
    },

    fetchBusinessData(businessId) {
      this.apiGetBusinessById(businessId).then(r => {
        this.shop = r.data;
      });
    },
    fetchProducts(businessId) {
      this.apiGetAllProductsFor(businessId).then(r => {
        this.products = r.data;
      })
    }
  },

  mounted() {
    this.fetchBusinessData(this.$route.params.id);
    this.fetchProducts(this.$route.params.id);
  }
}
</script>