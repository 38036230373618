<template>
  <div>
    <v-container v-if="shop">
      <v-row>
        <v-col>
          <h3 class="page-headline small">Bestellung • {{ shop.label }}</h3>
          <div class="page-divider small mb-4"/>
          <v-card>
            <v-card-text class="pb-0">
              <v-alert type="warning" elevation="5" v-if="shop.deliveryFrom != null && shop.deliveryFrom > cartTotalSum"
                       outlined
                       shaped>
                Du hast den Mindestbestellwert (<b>{{ shop.deliveryFrom | money }}</b>) für eine Lieferung nicht
                erreicht.
                Du kannst daher nur zum abholen bestellen.
              </v-alert>

              <v-text-field light v-model="order.name" solo prepend-inner-icon="mdi-account"
                            placeholder="Vor- und Nachname" persistent-placeholder/>

              <v-text-field light v-model="order.phoneNumber" solo prepend-inner-icon="mdi-cellphone-text"
                            placeholder="Telefonnummer" persistent-placeholder/>

              <v-alert type="info" elevation="5">
                Deinen Spieler Identifier bekommst du, wenn du im Spiel im Chat (
                <v-icon size="20">mdi-alpha-t-box-outline</v-icon>
                ) den Befehl <b>/identifier</b> ausführst.<br/>
                Dein Spieler-Identifier befindet sich dann <b>automatisch</b> in
                deinem Zwischenspeicher. Du kannst ihn dann mit <b>STRG + V</b> hier her kopieren.
              </v-alert>

              <v-text-field light v-model="order.playerIdentifier" solo prepend-inner-icon="mdi-key-chain-variant"
                            placeholder="Spieler Identifier" persistent-placeholder height="50"
                            :rules="playerIdentifierRule"/>

              <v-text-field light v-model="order.location" solo prepend-inner-icon="mdi-home-modern"
                            placeholder="Ortsbeschreibung / Wohnung / Anwesen" persistent-placeholder/>

              <v-select light v-model="deliveryType" :items="availableDeliveryTypes" solo
                        prepend-inner-icon="mdi-moped-outline"/>

              <v-switch label="Meine Daten merken" v-model="order.rememberMe" class="pt-0 pb-0"/>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
              <v-btn color="info" @click="createOrder">
                <v-icon class="pr-2">mdi-contactless-payment-circle-outline</v-icon>
                Für {{ cartTotalSum | money }} kaufen
              </v-btn>
              <v-spacer/>
              <v-btn color="secondary" @click="toMenu">
                <v-icon class="pr-2">mdi-book-outline</v-icon>
                Zurück zur Speisekarte
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="12" sm="12">
          <h3 class="page-headline small">Mein Warenkorb</h3>
          <div class="page-divider small mb-4"/>

          <cart-overview :shop="shop"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import CartOverview from "@/components/CartOverview.vue";

const DELIVERY_TYPE_TAKEAWAY = 'Selbst abholen';
const DELIVERY_TYPE_DELIVERY = 'Lieferung';

export default {
  components: {CartOverview},
  data:       () => ({
    shop:     null,
    showCart: false,

    deliveryType: DELIVERY_TYPE_TAKEAWAY,

    order: {
      name:             '',
      phoneNumber:      '',
      playerIdentifier: '',
      rememberMe:       false,
      location:         '',
    },

    playerIdentifierRule: [
      value => {
        if (value.length === 'e440f7bdc7d0127adc2f5a7f884a244f8169e1b8'.length) return true;
        return 'Der Identifier ist nicht korrekt.';
      }
    ]
  }),
  computed:   {
    availableDeliveryTypes() {
      if (!(this.shop.deliveryFrom != null && this.shop.deliveryFrom > this.cartTotalSum)) {
        return [DELIVERY_TYPE_TAKEAWAY, DELIVERY_TYPE_DELIVERY];
      }
      return [DELIVERY_TYPE_TAKEAWAY];
    }
  },
  watch:      {
    deliveryType(newVal) {
      let cart      = this.$store.state.cart;
      cart.delivery = newVal === DELIVERY_TYPE_DELIVERY;
      this.$store.commit('update', {cart: cart});
    }
  },
  methods:    {
    createOrder() {
      let orderObj = {
        location:         this.order.location,
        phoneNumber:      this.order.phoneNumber,
        name:             this.order.name,
        playerIdentifier: this.order.playerIdentifier,
        useDelivery:      this.order.deliveryType === DELIVERY_TYPE_DELIVERY,
        business:         this.shop,
        products:         this.cart.items,
      }

      if (orderObj.playerIdentifier.length !== 'e440f7bdc7d0127adc2f5a7f884a244f8169e1b8'.length) {
        Swal.fire({
          icon: 'error',
          html: 'Dein Spieler-Identifier scheint nicht korrekt zu sein! Bitte überprüfe diesen.',
        })
        return;
      } else if (orderObj.location.length < 3 || orderObj.phoneNumber.length !== 8 || orderObj.name.length < 3) {
        Swal.fire({
          icon: 'error',
          html: 'Deine Personenbezogenen Daten (Telefonnummer, Vor- und Nachname und Standortbeschreibung) müssen korrekt ausgefüllt sein.'
        })
        return;
      }

      if (this.order.rememberMe) {
        this.rememberMe();
      } else {
        this.removeRememberMe();
      }

      this.apiCreateOrder(orderObj).then(r => {
        this.cartEmpty();
        this.$router.push('/verify/' + r.data.id);
      })
    },
    fetchBusinessData(businessId) {
      this.apiGetBusinessById(businessId).then(r => {
        this.shop = r.data;
      });
    },

    rememberMe() {
      this.$store.commit('update', {rememberMe: this.order});
    },
    removeRememberMe() {
      this.$store.commit('update', {rememberMe: null});
    },
    loadRememberMe() {
      if (this.$store.state.rememberMe) {
        this.order = this.$store.state.rememberMe;

        this.order.rememberMe = true;
      }
    },

    toMenu() {
      this.$router.push('/menu/' + this.$route.params.id);
    }
  },
  mounted() {
    this.fetchBusinessData(this.$route.params.id);
    this.loadRememberMe();

    if (this.cart.delivery) {
      this.deliveryType = DELIVERY_TYPE_DELIVERY;
    }

    if (this.cartTotalSum <= 0) {
      this.toMenu();
    }
  },
}
</script>