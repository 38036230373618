<template>
  <div>
    <v-container>
      <v-card tile elevation="12">
        <v-card-text>
          <h1 class="page-headline x-small">{{ availableShops }} Anbieter in deiner Nähe (PLZ {{ zipCode }})</h1>
        </v-card-text>
        <div class="page-divider small"/>
        <v-card-text>
          <v-card v-for="(v, k) in orderedShops" :key="k" outlined class="mb-5">
            <v-card-text>
              <div class="d-flex">
                <v-avatar :size="128" color="primary">
                  <img :src="apiAttachmentUrl(v.avatar) + '?cache-reset=' + new Date()" v-if="v.avatar"/>
                  <img :src="require('../assets/takeaway.png')" v-else/>
                </v-avatar>
                <div class="pl-6 pt-2">
                  <h3 class="page-headline x-small pb-2">{{ v.label }}</h3>
                  <p class="page-text colored bold pa-0 ma-0">{{ v.genre }}</p>
                  <p class="page-text ma-0 pa-0">von {{ v.zipCode }} (ca. {{ v.distance }}m entfernt)</p>
                  <v-btn small class="mt-4" rounded color="primary" @click="openMenu(v)">
                    Speisekarte
                  </v-btn>
                </div>
              </div>
            </v-card-text>
            <v-card-text class="pt-0">
              <div class="page-text small bold" v-if="v.deliveryEnabled">
                {{ deliveryNote(v) }}
              </div>
              <div v-else class="page-text small colored-red bold">
                Keine Lieferung möglich!
              </div>
            </v-card-text>
            <div
                style="position: absolute; width: 100%; height: 100%;background-color: rgba(60, 60, 60, 0.3);top:0;backdrop-filter: blur(4px);"
                v-if="v.open === false">
              <h3 class="page-headline small center" style="padding-top: 77px;">
                Aktuell geschlossen 😭
              </h3>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>

export default {
  components: {},
  computed:   {
    zipCode() {
      return this.$store.state.zipCode * 1;
    },
    availableShops() {
      let count = 0;
      this.businessList.forEach(v => {
        if (v.open) count++;
      })
      return count;
    },

    orderedShops() {
      let shops = this.businessList;

      shops.forEach(v => {
        v.distance = this.distanceBetweenTwoZipCodes(v.zipCode * 1, this.zipCode * 1);

        if (!v.open) v.distance = 99999;
      })

      return shops.sort((a, b) => a.distance - b.distance);
    }
  },

  methods: {
    openMenu(shop) {
      this.cartEmpty();
      this.$router.push('/menu/' + shop.id);
      this.$store.commit('update', {lastShop: shop});
    },

    fetchShops() {
      this.apiGetAllBusinesses().then(r => {
        this.businessList = r.data;
      })
    }
  },

  data: () => ({
    businessList: [],
  }),

  mounted() {
    this.fetchShops();
  }
}
</script>