<template>
  <v-container>
    <v-card elevation="5">
      <v-card-title class="pt-2 pb-2 pl-3">
        Impressum
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-0">
        <v-alert type="error" elevation="5" outlined shaped>
          Diese Webseite wird im Rahmen eines FiveM-Rollenspiel Servers genutzt. Alle erhobenen Daten beziehen sich
          ausschließlich auf fiktive Charaktere und Unternehmen. Alle bestellbaren Produkte existieren nicht in der
          realen Welt.<br/><br/>

          Die Webseite erzielt keinen Umsatz und erhebt keine Daten über Nutzer. Daher wird nach §5 TMG kein (echtes) Impressum angegeben.
          Bei weiteren Nachfragen kann eine Anfrage bei der Projekt-Leitung auf dem Discord-Server <a
            href="https://discord.gg/alteravita" style="color: black;">https://discord.gg/alteravita</a> gestellt
          werden.
        </v-alert>
      </v-card-text>
      <v-divider/>
      <v-card-text>
        <b>Finanz- und Gewerbeamt von Altera Vita</b><br/>
        Occupation Avenue 7238<br/><br/>
        <b>Geschäftsführung</b><br/>
        Dipl.-Jur. Saren Vakkarien<br/>
        <b>Telefon</b>: 950-2942<br/>
        <b>E-Mail</b>: gewerbeamt@alteravitarp.de
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {}
</script>