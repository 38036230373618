import Vue from 'vue';
import axios from 'axios';
import moment from "moment";

let READY = false;

// load URL
axios.get('/config.json').then(r => {
    Vue.prototype.$SERVER_CONFIG = r.data || {};
    Vue.prototype.$SERVER_URL    = r.data.server_url || 'http://localhost:5100';
    console.log("Using Server: " + Vue.prototype.$SERVER_URL);
    READY = true;
});

axios.get('/postals.json').then(r => {
    Vue.prototype.$POSTALS = r.data;
    console.log('Loaded Postals ...')
})

const resolveIfReady = function (resolve, cb) {
    if (READY) {
        resolve(cb());
    } else {
        setTimeout(() => resolveIfReady(resolve, cb), 100);
    }
}

const waitForServer = function (cb) {
    return new Promise(function (resolve) {
        resolveIfReady(resolve, cb);
    });
};

export default {
    data:     () => ({}),
    filters:  {
        date(val) {
            return val ? moment(val).format('DD.MM.YYYY') : 'Unbekannt';
        },
        datetime(val) {
            return val ? moment(val).format('DD.MM.YYYY HH:mm:ss') : 'Unbekannt';
        },
        money(val) {
            return new Intl.NumberFormat('de-DE', {
                style:    'currency',
                currency: 'EUR',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            }).format(val);
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.user != null;
        },
        business() {
            return this.$store.state.user;
        },

        cart() {
            return this.$store.state.cart;
        },

        lastShop() {
            return this.$store.state.lastShop;
        },

        cartTotalSum() {
            let sum = 0;

            this.cart.items.forEach(cartItem => {
                sum += (cartItem.product.price * cartItem.count);
            })

            if (this.cart.delivery && this.lastShop.deliveryCost) {
                sum += this.lastShop.deliveryCost;
            }

            return sum;
        },
    },

    methods: {
        distanceBetweenTwoZipCodes(zipCode1, zipCode2) {
            let coords1 = Vue.prototype.$POSTALS.find(obj => obj.code === zipCode1)
            let coords2 = Vue.prototype.$POSTALS.find(obj => obj.code === zipCode2)

            if (coords1 && coords2) {
                return Math.floor(Math.sqrt(Math.pow(coords2.x - coords1.x, 2) + Math.pow(coords2.y - coords1.y, 2)));
            } else {
                return zipCode1 > zipCode2 ? zipCode1 - zipCode2 : zipCode2 - zipCode1;
            }
        },

        authErrorHandler(err) {
            if (err.response.status === 401) {
                this.$router.push('/logout');
            }
            throw err;
        },

        apiImageUrl(imageName, extension) {
            return this.$SERVER_URL + '/images/' + imageName + '.' + (extension || 'png');
        },

        apiAttachmentUrl(attachment) {
            return this.$SERVER_URL + '/api/shared/attachment/' + attachment.id;
        },

        apiDeleteAttachment(attachmentId) {
            return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/shared/attachment/' + attachmentId, {withCredentials: true}).catch(this.authErrorHandler));
        },

        apiSignIn(username, password, playerIdentifier) {
            return axios.post(this.$SERVER_URL + '/api/authenticate', {
                username:         username,
                password:         password,
                playerIdentifier: playerIdentifier,
            }, {withCredentials: true})
        },

        apiSignOut() {
            this.$store.commit('update', {user: null})
            this.updateVuetifyTheme();
            axios.delete(this.$SERVER_URL + '/api/authenticate', {withCredentials: true}).finally(() => {
                this.$router.push('/');
            }).catch(this.authErrorHandler);
        },

        apiSetMyPassword(currentPassword, newPassword1, newPassword2) {
            return axios.post(this.$SERVER_URL + '/api/security/@me/change-password', {
                currentPassword:  currentPassword,
                newPassword:      newPassword1,
                newPasswordCheck: newPassword2
            }, {withCredentials: true}).catch(this.authErrorHandler);
        },

        apiSetMyAvatar(avatar) {
            return axios.post(this.$SERVER_URL + '/api/security/@me/change-avatar', {
                image:    avatar,
                fileName: 'avatar.png'
            }, {withCredentials: true}).catch(this.authErrorHandler);
        },


        // api methods

        apiGetAllBusinesses() {
            return waitForServer(() => axios.get(this.$SERVER_URL + '/api/delivery/businesses', {withCredentials: true}).catch(this.authErrorHandler));
        },

        apiGetAllProductsFor(businessId) {
            return waitForServer(() => axios.get(this.$SERVER_URL + '/api/delivery/business/' + businessId + '/products', {withCredentials: true}).catch(this.authErrorHandler));
        },

        apiGetBusinessById(businessId) {
            return waitForServer(() => axios.get(this.$SERVER_URL + '/api/delivery/business/' + businessId, {withCredentials: true}).catch(this.authErrorHandler));
        },

        apiCreateOrder(orderObj) {
            return waitForServer(() => axios.post(this.$SERVER_URL + '/api/delivery/orders', orderObj, {withCredentials: true}).catch(this.authErrorHandler));
        },

        // cart
        cartEmpty() {
            this.$store.commit('update', {
                cart: {
                    items:    [],
                    delivery: false,
                }
            });
        },
        cartHasProduct(product) {
            let cart = this.$store.state.cart;

            let hasProduct = false;
            cart.items.forEach(cartItem => {
                if (cartItem.product.id === product.id) hasProduct = true;
            })

            return hasProduct;
        },
        cartAddProduct(product) {
            let cart = this.$store.state.cart;

            if (this.cartHasProduct(product)) {
                cart.items.forEach(cartItem => {
                    if (cartItem.product.id === product.id) cartItem.count++;
                })
            } else {
                cart.items.push({
                    product: product,
                    count:   1,
                })
            }

            this.$store.commit('update', {cart: cart});
        },
        cartRemoveProduct(product) {
            let cart = this.$store.state.cart;

            if (this.cartHasProduct(product)) {
                cart.items.forEach(cartItem => {
                    if (cartItem.product.id === product.id) cartItem.count--;
                })
            } else {
                cart.items.push({
                    product: product,
                    count:   1,
                })
            }

            this.cartCleanupEmptyProducts();
        },
        cartCleanupEmptyProducts() {
            let cart = this.$store.state.cart;
            let i    = cart.items.length;

            while (i--) {
                let cartItem = cart.items[i];
                if (cartItem.count <= 0) {
                    cart.items.splice(i, 1);
                }
            }
            this.$store.commit('update', {cart: cart});
        },
        deliveryNote(business) {
            let infos = [];

            // noinspection JSUnresolvedVariable
            if (business.deliveryFrom) {
                infos.push('Lieferung ab ' + this.$options.filters.money(business.deliveryFrom))
            }
            // noinspection JSUnresolvedVariable
            if (business.deliveryCost != null) {
                infos.push(this.$options.filters.money(business.deliveryCost) + ' Lieferkosten')
            }
            // noinspection JSUnresolvedVariable
            if (business.freeDeliveryFrom != null) {
                infos.push('Kostenlose Lieferung ab ' + this.$options.filters.money(business.freeDeliveryFrom))
            }

            return infos.join(' • ')
        },

        // libs

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload  = () => resolve(reader.result.split("base64,")[1]);
                reader.onerror = error => reject(error);
            });
        },

        updateVuetifyTheme() {
            this.$vuetify.theme.themes.light.primary = '#689F38';
            this.$vuetify.theme.themes.dark.primary  = '#689F38';

            this.$vuetify.theme.dark = true;
        },
    }
}
