<template>
  <div>Logging out ...</div>
</template>

<script>
export default {
  mounted() {
    this.apiSignOut();
  }
}
</script>